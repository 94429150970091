// Custom Theming for Angular Material
@use '@angular/material' as mat;
// For more information: https://material.angular.io/guide/theming
@import "variables";
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$UptimeDashboard-primary: mat.define-palette(mat.$indigo-palette);
$UptimeDashboard-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$UptimeDashboard-warn: mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$UptimeDashboard-theme: mat.define-light-theme($UptimeDashboard-primary, $UptimeDashboard-accent, $UptimeDashboard-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($UptimeDashboard-theme);

@import "scss/core";

// Import app.theme.scss
@import "app/app.theme";

mat-row {
    position: relative;

    &:hover {
        background-color: rgba(0, 0, 0, 0.05)
    }
}


mat-table {
    &.elevated-header {
        mat-header-row {
            //@include mat-elevation(2);
        }

    }
}

body {
    background-color: rgba(30, 33, 41, 0.05);
}


.needle-center, .needle {
    fill: #1E2129;
}

$accent_color: rgb(53, 58, 72);

.color-accent {
    color: $accent_color;
}

.color-warn {
    color: rgba(245, 124, 0, 1);
}

.color-error {
    color: rgba(213, 0, 0, 1);
}

.color-success {
    color: rgba(0, 213, 0, 1);
}

.background-accent {
    background-color: $accent_color;
}

.mat-tooltip {
    font-size: 14px !important;
}

.ellipsize {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}


.mat-mdc-icon-button.size-30 {
    width: 30px;
    height: 30px;
    line-height: 30px;

    .mat-icon {
        line-height: 24px;
        height: 16px;
        width: 16px;
        font-size: 20px;
    }
}

.small_button {
    background: white;
    display: inline-flex;
    border-radius: 100px;
    cursor: pointer;
}

.hidden {
    display: none;
}

.app_video_player {
    &.active {
        z-index: 10000;
        display: block;
    }

    position: fixed;
    bottom: 10px;
    right: 10px;
    width: 480px;
    height: 270px;
    z-index: 0;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.9);
    display: none;

    video {
        width: 100%;
        height: 100%;
    }

    &:hover .player_actions {
        display: flex;
    }

    .video-title {
        color: white;
        flex: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .player_actions {
        display: flex;
        position: absolute;
        z-index: 1;
        width: 100%;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.5);
        padding: 0 8px;

        align-items: center;

        mat-icon {
            color: white;
            cursor: pointer;
        }
    }
}


.warn-snackbar {
    background: white;
    color: black;
    padding: 8px !important;
    margin: 0 0 16px 0 !important;
}


html, body {     
}

body {
    margin: 0;
    font-family: ProximaNova-Regular, sans-serif;
}

.full-width {
    width: 100%;
}

.full-content-width {
    width: 100vw;
}

.sidebar-locked {
    .full-content-width {
        width: calc(100vw - #{$appSidebarExpandedWidth});
    }
}

.drag_handle {
    cursor: move;
}

@import "mixins/breakpoints";
@include media-breakpoint-down('sm') {
    .hide-sm {
        display: none !important;
    }
}

.mat-menu-panel {
    min-width: fit-content !important;
    padding: 0px 0px !important;
}

.cdk-overlay-pane {
    min-width: fit-content;
}

.header {
    --mdc-theme-primary: white;

    .mat-mdc-form-field-subscript-wrapper {
        display: none !important;
    }

    .mat-mdc-text-field-wrapper {
        background: none !important;
    }

    .mat-mdc-form-field-focus-overlay {
        background: none !important;
    }

    .mdc-floating-label, .mat-mdc-select-arrow, input::placeholder {
        color: rgba(255, 255, 255, 0.42) !important;
    }

    .mdc-text-field--focused .mdc-floating-label {
        color: white !important;
    }

    .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
        border-bottom-color: rgba(255, 255, 255, 0.42);
    }

    .mat-mdc-text-field-wrapper {
        padding: 0 !important;
    }

    input, .mat-mdc-select-value {
        color: white !important;
    }

}

.mat-card-title {
    display: block;
    margin-bottom: 8px;
    font-size: 24px;
    font-weight: 500;
}

.mat-card-subtitle {
    color: rgba(0, 0, 0, .54);
    font-size: 14px;
}

.mat-mdc-card {
    transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
    display: block;
    position: relative;
    padding: 16px !important;
    border-radius: 4px;
}

.mat-icon {
    font-size: 20px !important;
}

.nav-link-icon {
    font-size: 16px !important;
}

@font-face {
    font-family: ProximaNova-Regular;
    src: url(assets/fonts/ProximaNova-Regular.otf) format("opentype");
}

@font-face {
    font-family: ProximaNova-Bold;
    src: url(assets/fonts/ProximaNova-Bold.otf) format("opentype");
}

@font-face {
    font-family: ProximaNova-Semibold;
    src: url(assets/fonts/ProximaNova-Semibold.otf) format("opentype");
}

* {
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: grayscale;
}
